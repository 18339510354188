import * as React from 'react'

import { HorizontalAlignment } from '@thg-commerce/enterprise-core'
import { ButtonEmphases } from '@thg-commerce/gravity-elements'
import { TextStyling } from '@thg-commerce/gravity-patterns'
import { PictureProps } from '@thg-commerce/gravity-system'
import { withPrefetch } from '@thg-commerce/gravity-system/prefetch'
import {
  BreakpointArray,
  SpacingBox,
  TextEntry,
} from '@thg-commerce/gravity-theme'

import { GutterPaddingInterface, PaddingInterface } from '../theme'

import {
  Container,
  EditorialContent,
  EditorialItemContainer,
  EditorialItems,
  EditorialLink,
  EditorialLinkContainer,
  EditorialTitle,
  HighlightText,
  HoverPicture,
  PictureContainer,
  StyledAnchor,
  StyledContainer,
  StyledPicture,
  Subtitle,
  Title,
} from './styles'

const PrefetchEditorialLink = withPrefetch('href', EditorialLink)
const PrefetchStyledAnchor = withPrefetch('href', StyledAnchor)

export interface EditorialProps {
  title?: string
  subtitle?: string
  items: EditorialItemProps[]
  columns?: number
  horizontalAlignment?: HorizontalAlignment
  hasBorder?: boolean
  contentPadding?: boolean
  styleModifier?: string
  wraps: BreakpointArray<boolean>
  widths?: BreakpointArray<string>
  widgetIndex: number
  gutterPadding: GutterPaddingInterface
  itemsCTAAlignment?: HorizontalAlignment
  mobileCTAWidth?: string
  titleStyle?: {
    textEntry: TextEntry
  }
  anchorWidth?: string
}

export interface EditorialItemProps {
  enableEditorialItemFocusOutline?: boolean
  picture: PictureProps
  titleProps?: {
    title: string
    titleColour?: 'light' | 'dark'
  }
  highlightProps?: {
    subtitle: string
    subtitleColour?: 'light' | 'dark'
  }
  content?: string
  onHoverSrc?: PictureProps
  buttonContent?: string
  buttonLink?: string
  emphasis?: ButtonEmphases
  onClick?: (e: React.MouseEvent) => void
  horizontalAlignment?: HorizontalAlignment
  contentGutterPadding: PaddingInterface
  flexBasis?: BreakpointArray<string>
  order?: BreakpointArray<number>
  directions?: BreakpointArray<'row' | 'column'>
  lazy?: boolean
  itemCTAAlignment?: HorizontalAlignment
  mobileCTAWidth?: string
  font?: TextStyling
  ctaPadding?: SpacingBox
  anchorWidth?: string
}

export const EditorialItem = (props: EditorialItemProps) => {
  const editorialContent = (
    <React.Fragment>
      <PictureContainer
        isOnHover={!!props.onHoverSrc}
        onClick={(event) => {
          props.onClick && props.onClick(event)
        }}
        data-testid="editorial-item-image-container"
      >
        <StyledPicture
          aspectRatio={'1/1 auto'}
          lazy={props.lazy}
          {...props.picture}
        />
        {props.onHoverSrc && (
          <HoverPicture lazy aspectRatio={'1/1 auto'} {...props.onHoverSrc} />
        )}
      </PictureContainer>
      <StyledContainer
        horizontalAlignment={props.horizontalAlignment}
        contentGutterPadding={props.contentGutterPadding}
        data-testid="editorial-styled-container"
      >
        {(props.highlightProps || props.titleProps || props.content) && (
          <React.Fragment>
            {props.highlightProps && (
              <HighlightText
                textColour={props.highlightProps.subtitleColour}
                data-testid="editorial-item-highlight-text"
                horizontalAlignment={props.horizontalAlignment}
              >
                {props.highlightProps.subtitle}
              </HighlightText>
            )}
            {props.titleProps && (
              <EditorialTitle
                textColour={props.titleProps.titleColour}
                data-testid="editorial-item-title"
                horizontalAlignment={props.horizontalAlignment}
                font={props.font}
              >
                {props.titleProps.title}
              </EditorialTitle>
            )}
            {props.content && (
              <EditorialContent
                content={props.content}
                horizontalAlignment={props.horizontalAlignment}
              />
            )}
          </React.Fragment>
        )}
        {props.buttonContent && props.buttonLink && (
          <EditorialLinkContainer
            alignment={props.itemCTAAlignment || props.horizontalAlignment}
          >
            <PrefetchEditorialLink
              href={props.buttonLink}
              emphasis={props.emphasis || 'medium'}
              onClick={(event) => {
                props.onClick && props.onClick(event)
              }}
              mobileCTAWidth={props.mobileCTAWidth}
              ctaPadding={props.ctaPadding}
            >
              {props.buttonContent}
            </PrefetchEditorialLink>
          </EditorialLinkContainer>
        )}
      </StyledContainer>
    </React.Fragment>
  )

  return props.buttonLink ? (
    <PrefetchStyledAnchor
      href={props.buttonLink}
      tabIndex={
        !props.buttonContent || props.enableEditorialItemFocusOutline ? 0 : -1
      }
      directions={props.directions || ['column']}
      data-testid="editorial-item-container-link"
      withFocus={!props.buttonContent && !props.titleProps?.title}
      anchorWidth={props.anchorWidth}
      enableEditorialItemFocusOutline={props.enableEditorialItemFocusOutline}
    >
      {editorialContent}
    </PrefetchStyledAnchor>
  ) : (
    editorialContent
  )
}

export const Editorial = ({
  title,
  subtitle,
  titleStyle,
  items,
  horizontalAlignment,
  hasBorder = false,
  gutterPadding,
  styleModifier,
  wraps,
  widths = ['100%'],
  widgetIndex,
  itemsCTAAlignment,
  mobileCTAWidth,
  anchorWidth,
}: EditorialProps) => {
  const editorialItemsMap = items.map((item, index) => {
    return (
      item && (
        <EditorialItemContainer
          key={index}
          hasBorder={hasBorder || styleModifier === 'cardStyle'}
          flexBasis={item.flexBasis}
          order={item.order}
          data-testid={`editorial-grid-item-container-id-${index}`}
        >
          <EditorialItem
            {...item}
            horizontalAlignment={horizontalAlignment}
            lazy={widgetIndex > 1}
            contentGutterPadding={item.contentGutterPadding}
            itemCTAAlignment={itemsCTAAlignment}
            mobileCTAWidth={mobileCTAWidth}
            anchorWidth={anchorWidth}
          />
        </EditorialItemContainer>
      )
    )
  })

  return (
    <Container containerPadding={gutterPadding}>
      {title && (
        <Title textEntry={titleStyle && titleStyle.textEntry}>{title}</Title>
      )}
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
      <EditorialItems widths={widths} wraps={wraps} gap={gutterPadding.gap}>
        {editorialItemsMap}
      </EditorialItems>
    </Container>
  )
}
